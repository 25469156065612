.paging-icon {
  height: 24px !important;
}
.header {

  position: relative;
  height: 64px;
  background-color: var(--primaryColor);

  > div {
    z-index: 10;
  }
  > span {
    z-index: 10;
  }

  &_logo {
    height: 32px;
    svg {
      height: 100%;
    }
    img {
      height: 100%;
    }
  }

  &_dlogo {
    height: 24px;
    svg {
      height: 100%;
    }
    img {
      height: 100%;
    }
  }

  a {
    height: 64px;
    line-height: 64px;
  }

  &_active {
    box-shadow: inset 0 -4px 0 var(--primaryText);
  }
  
}

.popup {
  &-import {
    width: 640px;
  }
  &-variation {
    width: 90%;
    max-width: 1020px !important;
    textarea {
      height: 200px !important;
    }
  }
  &-scan {
    width: 840px;
    textarea {
      height: 100% !important;
      font-family: "courier new";
      font-size: 14px;
    }
  }
  &-scan-detail {
    width: 420px !important;
  }
  &-notify {
    width: 540px;
    height: calc(100vh - 44px);
  }
  &-form {
    &-cells {
      gap: 12px !important;
    }
  }
  &_logo {
    flex: 0 0 auto;
    height: 44px;
    position: relative;
  }
}

.sl {
  display: flex;
  flex-direction: column;
  &-row {
    flex: 0;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    background-color: #EEEEEE;
    padding: 8px 12px;
    margin-top: 4px;
  }
}