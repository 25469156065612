@use "sass:color";
@import '@/components/variables.scss';

$x-trn-primary-color: color.scale(#4287f5, $lightness: 90%);
$x-trn-green-color: color.scale(#24a924, $lightness: 90%);
$x-trn-red-color: color.scale(#DD0000, $lightness: 90%);
$x-trn-yellow-color: color.scale(#ce7e16, $lightness: 90%);

.paging-icon {
  height: 24px !important;
}
.header {

  position: relative;
  height: 64px;
  background-color: var(--primaryColor);

  > div {
    z-index: 10;
  }
  > span {
    z-index: 10;
  }

  &_logo {
    height: 128px;
    svg {
      height: 100%;
    }
    img {
      height: 100%;
    }
  }

  &_dlogo {
    height: 24px;
    svg {
      height: 100%;
    }
    img {
      height: 100%;
    }
  }

  a {
    height: 64px;
    line-height: 64px;
  }

  &_active {
    box-shadow: inset 0 -4px 0 var(--primaryText);
  }

  &_reg_logo {
    height: 64px;
    width: 64px;
    img {
      height: 100%;
    }
  }
  
}

.scand {

  display: flex;
  flex-direction: column;
  gap: spacer(xl);
  > div {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    position: relative;
  }
  &-photo {
    height: 420px;
    min-width: 320px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

}

.popup {
  &-import {
    width: 640px;
  }
  &-import-copy {
    width: 1200px;
    textarea {
      width: 100% !important;
      height: calc(100vh - 244px) !important;
    }
  }
  &-attr {
    width: 420px;
  }
  &-scan {
    width: 840px;
    textarea {
      width: 400px !important;
      height: 100% !important;
      font-family: "courier new";
      font-size: 14px;
    }
  }
  &-scan-detail {
    width: 420px;
  }
}

.refresh {
  &-green {
    background-color: $x-trn-green-color !important;
  }
  &-red {
    background-color: $x-trn-red-color !important;
  }
  &-blue {
    background-color: $x-trn-primary-color !important;
  }
  &-yellow {
    background-color: $x-trn-yellow-color !important;
  }  
  &-primary {
    background-color: $x-trn-primary-color !important;
  }
  &-paid {
    background-color: $x-trn-green-color !important;
    font-weight: 700 !important;
    color: var(--primaryColor);
  }
}

.row-selected {
  box-shadow: inset -5px 0 0 var(--primaryColor);
}

.scan-details {
  width: 30%;
  max-width: 540px;
  border-left: 1px solid #DDDDDD;
  transition: all 0.5s ease;
  &-hide {
    width: 0;
    border: 0;
  }
}

.login {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &_blur {
    display: block;
    position: absolute;
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.2);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-header {

    position: fixed;
    height: 84px;

    &_logo {
      height: 44px;
      svg {
        height: 100%;
      }
      img {
        height: 100%;
      }
    }

  }

  &-form {
  
    flex: 0 0 auto;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: spacer(xxl);
    // background-color: rgba(0,0,0,0.5);
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.02);
    border-radius: spacer(base);
    margin-top: spacer(xxl);
    width: 480px;
  
    label {
      color: rgba(255,255,255,0.7);
      font-size: .875rem;
      padding-bottom: 4px;
    }

    input {
      flex: 0 0 auto;
      height: 44px;
      background-color: rgba(255,255,255,0.9);
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      padding-left: 12px;
      padding-right: 12px;
      width: 100%;
    }

    button {
      flex: 0 0 auto;
      height: 44px;
      background-color: var(--primaryColor);
      color: var(--primaryText);
      border-radius: 4px;
      padding-left: 12px;
      padding-right: 12px;
      width: 100%;
      appearance: none;
      border: 0;
      box-shadow: none;
    }
  
  }
  
}

.disclaimer {
  color: #FFFFFF;
  font-size: 12px;
  a {
    color: var(--primaryColor);
    font-size: 12px;
    text-decoration: underline;
  }
}