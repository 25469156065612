@import '@/components/variables.scss';

.p {
  display: block;
  min-height: 100vh;
  background-color: #FFFFFF;
  main {
    display: block;
    min-height: 100vh;
  }
  &c {
    display: block;
    width: 1600px;
    max-width: 100vw;
    padding-left: spacer(xl);
    padding-right: spacer(xl);
    margin: auto;
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-left: spacer(base);
      padding-right: spacer(base);
    }
  }
  &h {
    display: block;
    background-color: #FFFFFF;
    &r {
      display: flex;
      height: 64px;
      flex-direction: row;
      align-items: center;
      gap: spacer(xl);
      @media screen and (max-width: $x-breakpoint-xs) {
        gap: spacer(base);
      }
      > div {
        flex: 0 0 auto;
      }
      &s {
        flex: 1 1 auto !important;
      }
    }
    &l {
      display: block;
      height: 44px;
      width: calc(1233px * 44px/256px);
      position: relative;
      @media screen and (max-width: $x-breakpoint-xs) {
        width: 44px;
      }
    }
    &m {
      height: 44px;
      span {
        line-height: 44px;
        color: black;
      }
    }
    &ib {
      flex: 0 0 auto;
      display: flex;
      width: 32px;
      height: 44px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      svg {
        width: 24px;
        height: 24px;
        color: black;
      }
    }
  }
  &m {
    display: block;
    background-color: var(--primaryColor);
    color: var(--primaryText);
    height: 44px;
    &s {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: spacer(xl);
      a {
        flex: 0 0 auto;
        height: 44px;
        color: #FFFFFF;
        line-height: 44px;
      }
    }
  }
  section {
    padding-bottom: spacer(xl);
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-bottom: spacer(base);
    }
    &:first-child {
      padding-top: spacer(xl);
      @media screen and (max-width: $x-breakpoint-xs) {
        padding-top: spacer(base);
      }
    }
  }
}

.ad {
  display: block;
  background-color: var(--colorYellow);
  padding: spacer(base);
  border-radius: spacer(base);
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
  margin-top: spacer(xl);
  position: relative;
  overflow: hidden;
  @media screen and (max-width: $x-breakpoint-xs) {
    margin-top: spacer(base);
  }
  &-app {
    background-image: url("/cdn/images/stock/home-hero.jpg");
    background-position: center -50px;
    background-repeat: no-repeat;
  }
  &-sdw {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(2,0,36,1) 9%, rgba(255,255,255,0) 100%);
    z-index: 0;
  }
  &r {
    display: flex;
    flex-direction: row;
    gap: spacer(base);
    z-index: 1;
    position: relative;
    @media screen and (max-width: $x-breakpoint-xs) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    > div {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      gap: 4px;
      > div {
        flex: 0 0 auto;
      }
    }
  }
  &tl {
    display: block;
    font-size: 2rem;
    line-height: 2.2rem;
    color: white;
    font-weight: 700;
    padding-left: spacer(base);
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-left: 0;
      font-size: 1rem;
      line-height: 1rem;
    }
  }
  &st {
    display: block;
    font-size: 1.2rem;
    line-height: 1.3rem;
    color: white;
    padding-left: spacer(base);
    @media screen and (max-width: $x-breakpoint-xs) {
      padding-left: 0;
      font-size: 1rem;
      line-height: 1rem;
    }
  }
  &b {
    display: flex;
    flex-direction: row;
    gap: spacer(base);
    img {
      display: block;
      height: 64px;
      @media screen and (max-width: $x-breakpoint-xs) {
        height: 44px;
      }
    }
  }
}
.stc, div.stc {
  flex: 1 1 auto;
}
.button {

  &-base {
    background-color: var(--primaryColor);
    color: var(--primaryText);
    font-weight: 700;
    font-size: 1rem;
    padding-left: spacer(md);
    padding-right: spacer(md);
    display: flex;
    height: 44px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: var(--buttonBorderRadius);
    border: 1px solid transparent;
    position: relative;
    width: 100%;
    cursor: pointer;
    
    span {
      flex: 0 0 auto;
      + i {
        margin-left: spacer(xs)
      }
      + svg {
        margin-left: spacer(xs)
      }
    }
    
    i {
      + span {
        margin-left: spacer(xs)
      }
      svg {
        height: 20px;
        margin-bottom: -4px;
      }
      
    }
    
    > svg {
      display: none;
    }
    
    &:disabled {
      background-color: #DDDDDD;
      color: #999999;
      span {
        color: #999999;
      }
    }

  }
  
  &-rows {
    flex-direction: column;
  }

  &-inline {
    width: auto;
  }
  
  &-primary {
    background-color: var(--primaryColor);
    color: var(--primaryText);
    span {
      color: var(--primaryText);
    }
  }

  &-primary-line {
    background-color: white;
    border-color: var(--primaryColor);
  }
  
  &-line {
    background-color: transparent;
    border-color: var(--primaryColor);
    color: var(--primaryColor);
    span {
      color: var(--primaryColor);
    }
  }
  
  &-processing {
    color: #FFF;
    background-color: rgba(0,0,0,0.3);
    overflow: hidden;
    span {
      display: none;
    }
    > svg {
      height: 24px;
      display: block;
      flex: 0 0 auto;
      animation: processingSpinner 1s infinite, processingZoom 1s;
    }
  }
  
  &-icon {
    width: 44px;
    padding: 0 0;
    justify-content: center;
    svg {
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  &-icon-only {
    width: 44px;
    padding: 0 0;
    background-color: transparent;
    border-color: transparent;
    color: black;
    i {
      height: 20px;
      svg {
        width: 20px;
        height: 20px;
        transition: all 0.3s ease;
      }
    }
  }

  &-icon-toggle {
    width: 44px;
    padding: 0 0;
    background-color: var(--lightGrayColor);
    border-color: var(--lightGrayColor);
    color: black;
    transition: background-color 0.3s ease;
    i {
      height: 20px;
      svg {
        width: 20px;
        height: 20px;
        transition: all 0.3s ease;
      }
    }
  }

  &-icon-selected {
    background-color: var(--primaryColor);
    color: var(--primaryText);
  }

  &-green {
    background-color: var(--colorGreen);
    color: var(--primaryText);
    span {
      color: var(--primaryText);
    }
  }

  &-red {
    background-color: var(--colorRed);
    color: var(--primaryText);
    span {
      color: var(--primaryText);
    }
  }

  &-black {
    background-color: black;
    color: white;
    span {
      color: white;
    }
  }

  &-link {
    background-color: transparent;
    border-color: transparent;
    color: var(--primaryColor);
    height: auto;
  }

  &-ebay {
    background-color: #3665f3;
    color: #FFFFFF;
    span {
      color: #FFFFFF;
    }
  }

  &-twitter {
    background-color: black;
    color: white;
    width: auto;
    > svg {
      display: block;
      color: white;
      width: 20px !important;
      height: 20px !important;
    }
  }

}

@keyframes processingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes processingZoom {
  0% {
    height: 300px;
  }
  100% {
    height: 24px;
  }
}

.ft {
  display: block;
  background-color: black;
  padding: spacer(xxl);
  @media screen and (max-width: $x-breakpoint-xs) {
    padding: spacer(xl) spacer(base);
  }
  &gap {
    flex: 0 0 auto;
    padding-top: spacer(base);
  }
  &p {
    display: flex;
    flex-direction: row;
    gap: spacer(xxl);
    @media screen and (max-width: $x-breakpoint-xs) {
      flex-direction: column;
      gap: spacer(xl);
    }
  }
  &c {
    flex: 0 0 auto;
  }
  &s {
    flex: 1 1 auto;
  }
  &li {
    display: flex;
    flex-direction: column;
    color: white;
    text-decoration: none;
    gap: 8px;
    > div {
      flex: 0 0 auto;
    }
  }
  a {
    color: white;
  }
  &ic {
    display: flex;
    flex-direction: row;
    gap: spacer(base);
    &b {
      flex: 0 0 auto;
      width: 44px;
      height: 44px;
      border-radius: 100%;
      background-color: black;
      color: #FFFFFF;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.brc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  padding-bottom: spacer(xl);
  > div, a {
    flex: 0 0 auto;
  }
  a {
    color: black;
    text-decoration: none;
    &:hover {
      color: var(--primaryColor);
      text-decoration: underline;
    }
  }
}
.err {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: spacer(xxl);
  padding-top: 120px;
  padding-bottom: 120px;
  @media screen and (max-width: $x-breakpoint-xs) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  > div {
    flex: 0 0 auto;
    padding-left: spacer(xl);
    padding-right: spacer(xl);
    text-align: center;
  }
  h1 {
    font-size: 3rem;
    color: var(--primaryColor);
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 2rem;
    }
  }
  h3 {
    color: var(--colorRed);
    font-size: 2rem;
    @media screen and (max-width: $x-breakpoint-xs) {
      font-size: 1.5rem;
    }
  }
}
.tsf {
  display: block;
  position: relative;
  border-radius: var(--fieldBorderRadius);
  border: 1px solid var(--fieldBorderColor);
  height: 44px;
  box-sizing: border-box;
  background-color: white;
  input {
    border: 0;
    appearance: none;
    background-color: transparent;
    padding-left: 44px;
    padding-right: 8px;
    height: 42px;
    width: 100%;
  }
  svg {
    display: block;
    position: absolute;
    top: 11px;
    left: 11px;
    width: 20px;
    height: 20px;
    color: #999999;
  }
}
.frm {
  &i {
    display: block;
    position: relative;
    border-radius: var(--fieldBorderRadius);
    border: 1px solid var(--fieldBorderColor);
    height: 44px;
    box-sizing: border-box;
    background-color: white;
    width: 100%;
    input, textarea, select {
      display: block;
      position: relative;
      border: 0;
      appearance: none;
      background-color: transparent;
      padding-left: 12px;
      padding-right: 12px;
      height: 42px;
      width: 100%;
      z-index: 1;
      &::placeholder {
        color: black;
      }
      &::-webkit-input-placeholder {
        color: black;
      }
    }
    &dd {
      padding-right: 32px !important;
    }
    &i {
      display: block;
      position: absolute;
      z-index: 0;
      top: 13px;
      width: 16px;
      height: 16px;
      svg {
        display: block;
        width: 16px;
        height: 16px;
        color: #999999;
      }
    }
    &ri {
      right: 13px;
    }
  }
  &rws {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: spacer(base);
    &c {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}
.pop {
  &l {
    width: 480px;
  }
  &frm {
    padding: spacer(xl);
    background-color: #F5F5F5;
    border-radius: spacer(base);
  }
}